// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

// Chartkick
require("chartkick")
require("chart.js")

// Bootstrap
import "bootstrap"
import '../stylesheets/bootstrap4'

// Fontawesome
import "@fortawesome/fontawesome-free/js/all";
import '../stylesheets/fontawesome'

// Stylesheets
import '../stylesheets/loader'
import '../stylesheets/style'

require("packs/script")
