import $ from 'jquery';
import List from 'list.js';

$(function() {
  var list = new List('address-list', {
    valueNames: ['address', 'alt-address'],
    page: 10,
    pagination: {
      outerWindow: 2,
      item: "<li class='page-item'><a class='page page-link' href='#'></a></li>"
    }
  });

  $(".js-loading").addClass("hidden");
  $(".js-loading-content").removeClass("hidden");

  $('[data-toggle="tooltip"]').tooltip();
});
